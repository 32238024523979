<template>
  <BaseForm
    :title="title"
    :mod="mod"
    :flat="flat"
    :divider="divider"
    :dialog="dialog"
    :loading="loading"
    @save="save"
  >
    <template #content>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-text-field
              class="required"
              v-model="form.name"
              label="Nome:"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <v-select
              :items="states"
              label="Estado:"
              @change="loadCities"
              v-model="form.stateUf"
            ></v-select>
          </v-col>
          <v-col cols="8">
            <v-select
              v-model="form.CityId"
              label="Cidade:"
              placeholder="Selecione um estado"
              :items="cities"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #actions></template>
  </BaseForm>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import errorHandler from "@/helpers/error_handler";

import BaseForm from "@/components/template/BaseForm";

export default {
  components: {
    BaseForm,
  },
  mixins: [formMixin],
  directives: {},
  computed: {
    examReferenceId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      loading: false,
      form: {},
      states: [],
      cities: [],
    };
  },
  methods: {
    save(actionAfterOperation) {
      const errors = this.validateForm();

      if (errors.length) {
        this.$root.$errorDialog(errors, {
          width: "800",
          color: "primary",
        });
      } else {
        this[this.mod](actionAfterOperation);
      }
    },
    async insert(actionAfterOperation) {
      try {
        this.loading = true;

        const response = await this.$axios.post(
          `/partnerships/${this.$route.params.partnershipId}/branches`,
          this.form
        );

        this.loading = false;

        console.log("Operação concluída com sucesso");

        this.afterInsertion(actionAfterOperation, {
          ...this.form,
          id: response.data.id,
        });
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async update(actionAfterUpdate) {
      try {
        this.loading = true;

        await this.$axios.put(
          `/partnerships/${this.$route.params.partnershipId}/branches/${this.$route.params.id}`,
          this.form
        );
        this.loading = false;

        this.afterUpdate(actionAfterUpdate);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async loadCities(selectedState) {
      try {
        if (!selectedState) return;

        this.loadingCities = true;
        const response = await this.$axios.get(`/cities?uf=${selectedState}`);

        this.cities = response.data.map((c) => {
          return {
            text: c.name,
            value: c.id,
          };
        });
        this.loadingCities = false;
      } catch (error) {
        this.loadingCities = false;
      }
    },
    async loadStates() {
      try {
        this.loadingStates = true;
        const response = await this.$axios.get(`/states`);

        this.states = response.data.map((s) => {
          return {
            text: s.name,
            value: s.uf,
          };
        });
        this.loadingStates = false;
      } catch (error) {
        this.loadingStates = false;
      }
    },
    validateForm() {
      const errors = [];

      if (!this.form.CityId) errors.push("Cidade obrigatória!");

      if (!this.form.name) errors.push("Nome obrigatório!");

      return errors;
    },
    async loadPartnershipBranchesData() {
      try {
        this.loading = true;

        const response = await this.$axios.get(
          `/partnerships/${this.$route.params.partnershipId}/branches/${this.$route.params.id}`
        );

        this.setPartnershipBranchData(response.data);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async setPartnershipBranchData(data) {
      const copyOfData = { ...data };

      copyOfData.stateUf = copyOfData.City.State.uf;

      if (copyOfData.stateUf) await this.loadCities(copyOfData.stateUf);

      this.form = copyOfData;
    },
  },
  created() {
    this.loadStates();

    if (this.mod === "update") {
      this.loadPartnershipBranchesData();
    }
  },
};
</script>

<style>
.label-mask {
  font-size: 1.05rem;
}
</style>
